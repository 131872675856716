<template>
  <div>
    <web-header active-url="/achievements" borderBottom></web-header>
    <div class="page-article-list">
      <div class="f-wrap">
        <div class="page-header">
          <div class="page-header-en">RESEARCH</div>
          <span class="page-header-cn">研究成果</span>
        </div>
        <div class="page-list-search">
          <el-form :model="searchForm" class="search-form">
            <el-form-item>
              <el-select v-model="searchForm.year" size="medium" placeholder="年份" clearable>
                <el-option v-for="(item, index) in yearList" :label="item+'年'" :value="item" :key="index"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-select v-model="searchForm.month" size="medium" placeholder="月份" clearable>
                <el-option label="1月" value="1"></el-option>
                <el-option label="2月" value="2"></el-option>
                <el-option label="3月" value="3"></el-option>
                <el-option label="4月" value="4"></el-option>
                <el-option label="5月" value="5"></el-option>
                <el-option label="6月" value="6"></el-option>
                <el-option label="7月" value="7"></el-option>
                <el-option label="8月" value="8"></el-option>
                <el-option label="9月" value="9"></el-option>
                <el-option label="10月" value="10"></el-option>
                <el-option label="11月" value="11"></el-option>
                <el-option label="12月" value="12"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-input class="keywords" size="medium" v-model="searchForm.keywords" placeholder="关键字：" clearable></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="medium" @click="getDataList(false)">搜索</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="page-list-wrap">
          <a :href="'/news_detail/'+item.class_id+'/'+item.id" target="_blank" class="page-list-wrap-item" v-for="(item, index) in dataList" :key="index">
<!--            <div class="page-list-wrap-item-icon" v-if="item.image && item.image.img">-->
<!--              <el-image :src="item.image.img" style="width: 100%; height: 100%;"></el-image>-->
<!--            </div>-->
            <div class="page-list-wrap-item-content">
              <div class="page-list-wrap-item-content-header">
                <div class="title">{{ item.title }}</div>
                <div class="author">{{ item.author }}</div>
              </div>
              <div class="page-list-wrap-item-content-desc">
                {{ item.introduce }}
              </div>
              <div class="page-list-wrap-item-content-date">
                {{ dateFormat(item.send_time, 'YYYY-MM-DD') }}
              </div>
            </div>
          </a>
          <el-empty description="暂无内容" v-if="!dataList || dataList.length == 0"></el-empty>
        </div>
        <div class="page-list-pagination" v-if="page.total > page.pageSize">
          <el-pagination
              background
              @current-change="handleCurrentChange"
              :current-page="page.current"
              :page-size="page.pageSize"
              layout="prev, pager, next, jumper"
              :total="page.total">
          </el-pagination>
        </div>
        <div class="page-list-pagination-btn" v-if="page.total > page.pageSize">
          <el-button type="primary" size="medium" round @click="dataMore" v-if="!noneMore">查看更多</el-button>
          <div class="none-more" v-else>没有更多了</div>
        </div>
      </div>
    </div>
    <web-navigation></web-navigation>
    <web-footer></web-footer>
    <web-right-menu class="right-menu"></web-right-menu>
  </div>
</template>

<script>
import WebHeader from "@/components/webHeader";
import WebFooter from "@/components/webFooter";
import WebRightMenu from "@/components/webRightMenu";
import WebNavigation from "@/components/webNavigation";
import moment from  "moment"
import { articleList } from "@/api";
export default {
  name: 'Achievements',
  components: {
    WebHeader,
    WebFooter,
    WebRightMenu,
    WebNavigation
  },
  data() {
    return {
      dataList: [],
      yearList: [],
      // 分页
      page: {
        // 当前页
        current: 1,
        // 每页显示的条数
        pageSize: 10,
        orderBy: "send_time",
        sortedBy: "desc",
        // 总条数
        total: 0
      },
      searchForm: {
        year: "",
        month: "",
        keywords: ""
      },
      noneMore: false,
    }
  },
  created() {
    this.getParams();
    this.yearList = this.createYears(2016);
    this.getDataList(false);
  },
  methods: {
    // 获取参数
    getParams() {
      this.page.current = /^[1-9]\d*$/.test(this.$route.query.page) && Number(this.$route.query.page) > 0 ? Number(this.$route.query.page) : 1;
      if (this.$route.query.date) {
        let date = this.$route.query.date.split("-");
        if (date && date.length == 2) {
          this.searchForm.year = date[0];
          this.searchForm.month = date[1];
        } else {
          this.searchForm.year = this.$route.query.date;
        }
      }
      if (this.$route.query.keywords && decodeURIComponent(this.$route.query.keywords)) {
        this.searchForm.keywords = decodeURIComponent(this.$route.query.keywords);
      }
    },
    // 修改当前URL参数
    updateUrl() {
      // 获取当前URL主域名
      let url = window.location.origin;
      url = `${url}/achievements?page=${this.page.current}`;
      if (this.searchForm.year) {
        url = `${url}&date=${this.searchForm.year}`;
        if (this.searchForm.month) {
          url = `${url}&date=${this.searchForm.year}-${this.searchForm.month}`;
        }
      }
      if (this.searchForm.keywords && this.searchForm.keywords.trim()) {
        url = `${url}&keywords=${this.searchForm.keywords}`;
      }
      history.replaceState(null, null, url);
    },
    // 获取数据
    async getDataList(contact) {
      let param = {
        pageSize: this.page.pageSize,
        currentPage: this.page.current,
        orderBy: this.page.orderBy,
        sortedBy: this.page.sortedBy,
        search: "",
        searchFields: ""
      };
      if (this.searchForm.year && this.searchForm.month) {
        //计算月份的开始和结束时间，有的月份有31天，有的月份有30天，有的月份有28天，所以需要计算
        let startDate = moment(`${this.searchForm.year}-${this.searchForm.month}-01 00:00:00`).format("YYYY-MM-DD HH:mm:ss");
        //根据开始时间计算结束时间
        let endDate = moment(startDate).add(1, 'month').format("YYYY-MM-DD HH:mm:ss");
        param.search = `send_time:${startDate},${endDate}|`;
        param.searchFields = `send_time:between|`;
      }else if(this.searchForm.year){
        let startDate = moment(`${this.searchForm.year}-01-01 00:00:00`).format("YYYY-MM-DD HH:mm:ss");
        let endDate = moment(startDate).add(1, 'year').format("YYYY-MM-DD HH:mm:ss");
        param.search = `send_time:${startDate},${endDate}|`;
        param.searchFields = `send_time:between|`;
      }
      if (this.searchForm.year && this.searchForm.month) {
        param.search = `send_time:${this.searchForm.year}-${this.searchForm.month}-01|`;
        param.searchFields = `send_time:>|`;
      }
      if (this.searchForm.keywords) {
        param['words'] = this.searchForm.keywords;
      }
      let res = await articleList(param, 29);
      if (res && res.code == 200) {
        if (contact) {
          this.dataList.concat(res.data.list);
        } else {
          this.dataList = res.data.list;
        }
        this.page.total = res.data.total;
      }
      this.updateUrl();
    },
    // 分页，选中的分页
    handleCurrentChange(val) {
      this.page.current = val;
      this.getDataList(false);
    },
    // 加载更多
    dataMore() {
      let ps = this.page.current + 1;
      if (this.page.total > ps*this.page.pageSize) {
        this.page.current = this.page.current + 1;
        this.getDataList(true);
      } else {
        this.noneMore = true;
      }
    },
    // 日期格式化
    dateFormat(e, format) {
      return this.$moment(e).format(format);
    },
  }
}
</script>